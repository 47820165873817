import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { format } from 'date-fns'
import Layout from "../components/Layout"
import htmlDecode from "html-entities-decoder";

import { el } from 'date-fns/locale'


import Introduction from "../components/Global/IntroductionBg"
import UserIcon from "../images/user.svg";

import "../components/Blog/BlogPageTemplate.css"





function BlogPageTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://nextyounutrition.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://nextyounutrition.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout header={data.menu} footer={data.footer} appointment= {data.template.appCpt} metaData={constructMetaData(data.page, props.pageContext.currentPage)}  >
            <div>
                <section>
                    <Introduction backImg={data.page.blogPageAcf.indexImage} title={data.page.blogPageAcf.categorypage} noImage={true} />
                </section>
                <section>
                    <div className="container w-full m-auto my-24">
                        <div className="w-full flex flex-wrap">
                            {data.posts.nodes.map((post, i) => {
                                return (
                                    <article className="w-full md:w-1/2 lg:w-1/3 p-4" key={`post-tab-${i}`}>
                                        <div className="relative bp-article">
                                            <div>
                                                {post.featuredImage && (
                                                    <Img
                                                        fluid={post.featuredImage.imageFile.childImageSharp.fluid}
                                                        alt={post.featuredImage.altText}
                                                        className="h-full w-full shadow bp-img-wrapper"
                                                        loading="lazy"
                                                    />
                                                )}
                                            </div>
                                            <div className="absolute top-0 w-full text-white z-10">
                                                <div className="flex pt-4 px-8 ">
                                                    <div className="flex justify-center">
                                                        <div>
                                                            <img src={UserIcon} style={{ width: "20px", marginRight: "5px" }} />
                                                        </div>
                                                        <div>
                                                            {post.author.firstName}
                                                        </div>
                                                    </div>
                                                    <div className="px-2">-</div>
                                                    <div>{format(new Date(post.date),'dd MMMM, yyyy',{locale: el})}</div>
                                                </div>
                                            </div>
                                            <div className=" p-2 md:p-6 py-4 md:py-6  text-center bp-info">
                                                <h4>{htmlDecode(post.title)}</h4>
                                                <div className="bp-excerpt mb-4 mt-4" dangerouslySetInnerHTML={{__html: post.excerpt}}></div>
                                                <Link to={`/blog/${post.slug}/`}>
                                                    <button>Περισσότερα</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </article>
                                )
                            })}
                        </div>

                        <ul className="flex justify-center py-8">
                            {props.pageContext.currentPage === 2 && (
                                <li className="mr-4 relative"  >
                                    <Link
                                        to={`/blog/`}
                                        className={"py-2 px-4 pagination-link bp-arrow"}
                                    >
                                    </Link>
                                </li>
                            )}

                            {props.pageContext.currentPage > 2 && (
                                <li className="mr-4 relative"  >
                                    <Link
                                        to={`/blog/${props.pageContext.currentPage - 1}/`}
                                        className={"py-2 px-4 pagination-link bp-arrow"}
                                    >
                                    </Link>
                                </li>
                            )}

                            {props.pageContext.numberOfPages > 1 && (Array.from({ length: props.pageContext.numberOfPages }).map((page, index) => {
                                return (
                                    <li key={index} className="mr-4"  >
                                        <Link
                                            to={index === 0 ? `/blog/` : `/blog/${index + 1}/`}
                                            className={props.pageContext.currentPage === index + 1 ? "active-pagination-link py-2 px-4 pagination-link" : "py-2 px-4 pagination-link"}
                                        >
                                            {index + 1}
                                        </Link>
                                    </li>
                                )
                            }))}

                            {props.pageContext.currentPage < props.pageContext.numberOfPages && (
                                <li className="mr-4 relative"  >
                                    <Link
                                        to={`/blog/${props.pageContext.currentPage + 1}/`}
                                        className={"py-2 px-4 pagination-link bp-arrow-r"}
                                    >
                                    </Link>
                                </li>
                            )}

                        </ul>
                    </div>
                </section>
            </div>

        </Layout>
    )
}

export default BlogPageTemplate;

export const blogPageQuery = graphql`
    query GET_BLOG_PAGE($id: ID! ,$startPoint: String!, $postsPerPage: Int!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                title
                blogPageAcf{
                    categorypage
                    indexImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{  
                                fluid(maxWidth: 2000, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                                
                            }
                        }
                    }
                }  
            }
            posts(after: $startPoint, first: $postsPerPage) {
                nodes {
                    title
                    excerpt
                    slug
                    date
                    author{
                        firstName
                        lastName
                        avatar{
                            url
                        }
                    }
                    featuredImage {
                        sourceUrl
                        altText
                        imageFile {
                            childImageSharp {
                                fluid{
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                }
            }
            template(id: "cG9zdDoyMDUw"){
                appCpt {
                    ...AppointmentFragment
                }
            }
            menu(id:"bmF2X21lbnU6MjI="){
                menuItems{
                    edges{
                        node{
                            url
                            label
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
            footer: template(id:"cG9zdDoyMjE5"){
                id
                title
                footerInformationTemplate{
                    address{
                        addressGoogleLink
                        addressLabel
                    }
                    hoursLabel
                    hoursTitle
                    informationTitle
                    supportEmail
                    telephoneNumber
                }
                footerAcf{
                    googlaMapsImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 750, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    city
                    callToActionText
                }
            }
        }
    }
`;
